<template>
  <div :class="$style.payment">
    <template v-if="isRoleSuperAdmin">
      <a-row :gutter="24" class="">
        <a-col :span="12">
          <a-select 
            v-model="sellerId" 
            allow-clear
            show-search
            class="w-100 mt-2 select-antd-default" 
            option-filter-prop="children"
            :filter-option="filterOption"
            placeholder="Pilih seller"
            :options="distributorSellerList"
            :disabled="fetchingDistributorSeller"
            @change="onSelectSeller"
          />
        </a-col>
      </a-row>
      <a-row :gutter="24" class="">
        <a-col :span="12">
          <a-alert
            v-if="!sellerId"
            class="mt-2"
            message="Pilih seller terlebih dahulu"
            type="warning"
            show-icon
          />
        </a-col>
      </a-row>
    </template>

    <a-row :gutter="24" class="mb-3 mt-3">
      <a-col :span="8">
        <a-input-search
          :value="searchPaymentNumber"
          :placeholder="$t('utils.search')"
          :loading="isLoading"
          :disabled="isLoading || !sellerId"
          @change="onSearchPaymentNumber"
        />
      </a-col>
      <a-col :span="5" :offset="11">
        <a-select
          :default-value="$route.query.paid_at_sort ?? 'desc'"
          class="w-100 select-antd-default"
          :placeholder="$t('finance.payment_status')"
          :options="options"
          @change="onSearchFilter"
        />
      </a-col>
    </a-row>
    
    <a-row class="d-flex align-items-center">
      <a-col :span="12" class="d-flex">
        <!-- Currentlt we don't use this search
        <div :class="$style.searchBox" class="mx-2">
          <a-input type="text" :placeholder="$t('userManage.search')" />
        </div>
        <SearchIcon class="mx-3" style="cursor: pointer" /> -->
      </a-col>
      <a-col :span="12" class="d-flex justify-content-end" style="gap: 0px 10px;">
        <a-button
          :class="$style.buttonConfirm"
          :disabled="!dataPayment.length"
          @click.prevent="exportDataTableCurrentPage"
        >
          {{ $t('warehouse.export_data') }}
        </a-button>
        <a-button
          :class="$style.buttonConfirm"
          @click="handleCreatePayment(true)"
        >
          Create Payment
        </a-button>
      </a-col>
    </a-row>

    <a-table
      class="payment-list mt-3 bg-white"
      :data-source="dataPayment"
      :row-key="(record, index) => index"
      :columns="columns"
      :loading="loading"
      :pagination="false"
      :scroll="scroll"
      :expanded-row-keys="arrExpandedRow"
      @expandedRowsChange="getIndex"
      @expand="getDetail"
    >
      <div slot="expandedRowRender" slot-scope="record">
        <a-skeleton :loading="loading">
          <div
            v-if="
              (record.payment?.term === 'CBD' ||
                record.payment?.term === 'COD' ||
                (record.payment?.term === 'CAD' &&
                  record.is_paid_first === true)) &&
                loading === false
            "
            class="d-flex"
          >
            <a-col :span="12" class="d-flex flex-column">
              <a-col :span="24" class="d-flex px-2 mb-2">
                <a-col :span="10">
                  Order Number
                </a-col>
                <a-col :span="14" class="d-flex">
                  {{ record.orders[0].order_number }}
                </a-col>
              </a-col>
              <a-col :span="24" class="d-flex px-2">
                <a-col :span="10">
                  Invoice Number
                </a-col>
                <a-col :span="14" class="d-flex">
                  {{ record.invoice_number }}
                </a-col>
              </a-col>
            </a-col>

            <a-col
              :span="12"
              class="d-flex flex-column align-items-end"
            >
              <div class="d-flex mb-2" style="gap: 0px 15px;">
                <div>Total Tagihan</div>
                <div class="mr-2">
                  {{ `Rp. ${toCurrency(record.order_amount)}` }}
                </div>
              </div>
              <div class="payment-tag rounded px-2">
                {{ `${record.payment_name_tag}${record.payment?.term === 'CAD' && record?.is_paid_first === true ? ' - MIX' : ''}` }}
              </div>
            </a-col>
          </div>

          <div
            v-if="
              record.payment?.term === 'CAD' &&
                record.is_paid_first === false &&
                loading === false
            "
            class="d-flex"
          >
            <a-col :span="12" class="d-flex flex-column">
              <a-col :span="24" class="d-flex px-2">
                <a-col :span="10">
                  Total Order Terbayar
                </a-col>
                <a-col :span="14" class="d-flex">
                  <div class="mr-2">
                    {{ `${record.order_count}` }}
                  </div>
                  <div
                    v-if="record.payment?.term === 'CAD'"
                    :class="$style.link"
                    @click="handleModalTagihan(true, record)"
                  >
                    Lihat
                  </div>
                </a-col>
              </a-col>
            </a-col>

            <a-col
              :span="12"
              class="d-flex flex-column justify-content-start align-items-center"
            >
              <a-col
                :span="24"
                class="d-flex flex-column align-items-end"
              >
                <div class="d-flex mb-2" style="gap: 0px 15px;">
                  <div>Total Tagihan</div>
                  <div class="mr-2">
                    {{ `Rp. ${toCurrency(record.total_cad_amount)}` }}
                  </div>
                </div>
                <div class="payment-tag rounded px-2">
                  {{ `${record.payment_name_tag}${record.payment?.term === 'CAD' && record?.is_paid_first === true ? ' - MIX' : ''}` }}
                </div>
              </a-col>
            </a-col>
          </div>
        </a-skeleton>
      </div>
    </a-table>

    <!-- Modal Create Payment -->
    <ModalCreatePayment
      v-if="modalCreatePayment"
      @handleCreatePayment="handleCreatePayment"
    />

    <!-- Modal Order Paid -->
    <ModalOrderPaid
      :visible="showModalOrderPaid"
      :list="listTagihan"
      :loading="loading"
      :pages="pages"
      :row="rowData"
      :paginations="paginationTagihan"
      @handleOrderPaid="(param) => (showModalOrderPaid = param)"
    />
    <div class="mt-4 text-right">
      <a-pagination
        v-model="page"
        :page-size="size"
        :page-size-options="sizeOptions"
        :total="pagination.total_items"
        :show-total="
          (total, range) =>
            $t('utils.pagination_show_total', {
              perpage: `${range[0]} - ${range[1]}`,
              total,
            })
        "
        :disabled="!pagination.total_items"
        show-size-changer
        @change="changePage"
        @showSizeChange="changePage"
      />
    </div>
  </div>
</template>

<script>
import historyPayment from '../composables/historyPayment'
import ModalCreatePayment from './ModalCreatePayment.vue'
import ModalOrderPaid from './ModalOrderPaid.vue'
import Pagination from '@/components/Pagination/index.vue'
import { useCurrency } from '@/composable/useCurrency'

const { format } = useCurrency()

export default {
  name: 'PaymentHistory',
  components: {
    ModalCreatePayment,
    ModalOrderPaid,
    Pagination,
  },
  setup() {
    const {
      dataPayment,
      modalCreatePayment,
      modalOrderPaid,
      warehouseData,
      page,
      size,
      pages,
      pagination,
      paginationTagihan,
      getListHistoryPayment,
      initialData,
      listTagihan,
      loading,
      changePage,
      handleOrderPaid,
      handleCreatePayment,
      handleWarehouse,
      getInvoiceNumber,
      fetchListTagihan,
      sellerId,
      businessId,
      isRoleSuperAdmin,
      filterOption,
      distributorSellerList,
      fetchingDistributorSeller,
      onSelectSeller,
      exportDataTableCurrentPage,
      searchPaymentNumber,
      onSearchPaymentNumber,
    } = historyPayment()

    return {
      dataPayment,
      modalCreatePayment,
      modalOrderPaid,
      warehouseData,
      page,
      size,
      pages,
      pagination,
      paginationTagihan,
      getListHistoryPayment,
      initialData,
      listTagihan,
      loading,
      changePage,
      handleOrderPaid,
      handleCreatePayment,
      handleWarehouse,
      getInvoiceNumber,
      fetchListTagihan,
      sellerId,
      businessId,
      isRoleSuperAdmin,
      filterOption,
      distributorSellerList,
      fetchingDistributorSeller,
      onSelectSeller,
      exportDataTableCurrentPage,
      searchPaymentNumber,
      onSearchPaymentNumber,
    }
  },
  data: function () {
    return {
      lastIndex: null,
      totalRows: 0,
      sizeOptions: ['10', '20', '30', '40', '50'],
      columns: [
        {
          title: 'No. Pembayaran',
          dataIndex: 'payment_number',
          key: 'payment_number',
        },
        {
          title: 'Total Bayar',
          dataIndex: 'payment_amount',
          key: 'payment_amount',
          ellipsis: true,
          customRender: value => {
            const temp = value && this.toCurrency(value)
            if (temp) return 'Rp. ' + temp
            else return '-'
          },
        },
        {
          title: 'Tanggal Bayar',
          dataIndex: 'paid_at',
          key: 'paid_at',
          customRender: value => {
            const time =
              value && this.$moment(value).format('DD MMM YYYY HH:mm')
            if (time) return time
            else return '-'
          },
        },
        {
          title: 'Metode Pembayaran',
          dataIndex: 'payment_type',
          key: 'payment_type',
          ellipsis: true,
        },
        {
          title: 'Nama Pembayaran',
          dataIndex: 'payment_name',
          key: 'payment_name',
          ellipsis: true,
        },
      ],
      rowData: {},
      showModalExportExcel: false,
      arrExpandedRow: [],
      screenHeight: screen.height - 520,
      options: [
        { label: 'Tanggal Terlama', value: 'asc' },
        { label: 'Tanggal Terbaru', value: 'desc' },
      ],
      isLoading: false,
      showModalOrderPaid: false,
    }
  },
  computed: {
    scroll() { 
      return { y: this.screenHeight }
    },
  },
  watch: {
    page: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.getIndex([])
      },
    },
  },
  mounted() {
  },
  methods: {
    handleModalTagihan(visible, item) {
      const page = 1
      this.fetchListTagihan(item, page)
      this.showModalOrderPaid = visible
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    getIndex(item) {
      this.arrExpandedRow = item
      if (item.slice(-1)[0] !== undefined) {
        const temp = item.slice(-1)[0]
        this.lastIndex = temp
      }
    },
    getDetail(visible, record) {
      if (
        (this.lastIndex !== null || this.lasIndex !== undefined) &&
        visible === true
      ) {
        this.fetchListTagihan(record, 1)
        this.getInvoiceNumber(record, this.lastIndex)
      }
      this.rowData = record
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
    },
    onSearchFilter(value) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          paid_at_sort: value,
          page: 1,
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '../style.module.scss';
</style>
<style lang="scss">
.payment-tag {
  background-color: #2196f3;
  color: white;
  min-width: 200px;
  text-align: center;
}
</style>
