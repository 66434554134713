import { reactive, toRefs, onMounted, watch, computed } from 'vue'
import { notification } from 'ant-design-vue'
import store from '@/store'
import route from '@/router'
import debounce from 'lodash/debounce'
import { getSeller, getPaymentHistory } from '@/api/channels/distributor'
import { getInvoiceList, getInvoiceDetail, getListTagihan } from '@/api/invoice'
import { getWarehouseList } from '@/api/warehouse'
import {
  getDistributor,
} from '@/api/channels/distributor'
import moment from 'moment'
import XLSX from 'xlsx';

const paymentName = [
  {
    label: 'Cash Before Delivery',
    value: 'CBD',
  },
  {
    label: 'Cash On Delivery',
    value: 'COD',
  },
  {
    label: 'Cash After Delivery',
    value: 'CAD',
  },
]

export default () => {
  const state_ = reactive({
    dataPayment: [],
    exportDataPayment: [],
    modalCreatePayment: false,
    modalOrderPaid: false,
    warehouseData: [],
    selectedWarehouse: '',
    businessId: '',
    pagination: {
      size: 20,
      total_items: 0,
    },
    initialData: '',
    loading: false,
    listTagihan: [],
    paginationTagihan: {
      size: 10,
      total_items: 0,
    },
    pages: 1,
    sellerId: undefined,
    distributorSellerList: [],
    fetchingDistributorSeller: false,
    fetchingSellerByWhsId: false,
    defaultSellerId: undefined,
  })

  onMounted(() => {
    getBusinessId()
    getWarehouse()
  })

  watch(
    () => state_.selectedWarehouse,
    () => {
      // getListHistoryPayment({
      //   page: 1,
      // })
    },
  )

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const size = computed(() => {
    return +route.app._route.query.limit || 10
  })

  const isRoleSuperAdmin = computed(() => {
    return store.state.user?.role_id === '73ed8e78-bb8f-4d90-8547-d5fb54d44b82' || store.state.user.role_name?.toLowerCase() === 'business admin'
  })

  const defaultBusinessId = computed(() => {
    return store.state.user.businessList?.[0]?.business_id
  })

  const searchPaymentNumber = computed(() => {
    return state_.sellerId ? route.app._route.query.search_number : undefined
  })

  const handleCreatePayment = param => {
    state_.modalCreatePayment = param
  }

  const handleOrderPaid = param => {
    if (param === false) {
      const temp = { ...route.app._route.query }
      delete temp.seller_id
      route.push({
        query: temp,
      })
      state_.listTagihan = []
      state_.paginationTagihan.page = 1
    }
    state_.modalOrderPaid = param
  }

  const getBusinessId = async () => {
    state_.businessId = store.state.user.businessList?.[0]?.business_id
    return await store.state.user.businessList?.[0]?.business_id
  }


  const fetchDistributorSeller = async () => {
    state_.fetchingDistributorSeller = true
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    getDistributor({
      headers: {
        'Business-Id': businessId,
      },
      params: {
        page: 1,
        size: 999,
      },
    })
    .then(({ data: { data: res } }) => {
      if (res?.records?.length > 0) {
        state_.distributorSellerList = res.records.map(f => ({ label: f.name, value: f.id }))
      } else {
        state_.distributorSellerList = []
        notification.error({
          message: 'Terjadi kesalahan',
          description: 'Gagal memuat data distributor seller',
        })
      }
      state_.fetchingDistributorSeller = false
    })
    .catch(err => {
      state_.distributorSellerList = []
      notification.error({
        message: 'Terjadi kesalahan',
        description: 'Gagal memuat data distributor seller',
      })
      console.error(err)
      state_.fetchingDistributorSeller = false
    })
  }

  const fetchSellerByWhsId = async (whsId) => {
    state_.fetchingSellerByWhsId = true
    const businessId = route.app._route.query.business_id || defaultBusinessId.value
    const bodyValue = {
      businessId: businessId,
      params: {
        warehouse_id: whsId,
      },
    }
    getSeller(bodyValue)
    .then(({ data: { data: res } }) => {
      if (res?.length > 0) {
        state_.defaultSellerId = res.length ? res[0].seller_id : undefined
        if (state_.defaultSellerId) {
          state_.sellerId = state_.defaultSellerId 
        }
      } else {
        state_.defaultSellerId = undefined
        notification.error({
          message: 'Terjadi kesalahan',
          description: 'Gagal mendapatkan seller data',
        })
      }
      state_.fetchingSellerByWhsId = false
    })
    .catch(err => {
      state_.defaultSellerId = undefined
      notification.error({
        message: 'Terjadi kesalahan',
        description: 'Gagal mendapatkan seller data',
      })
      console.error(err)
      state_.fetchingSellerByWhsId = false
    })
  }

  const getListHistoryPayment = async ({ page }) => {
    state_.loading = true
    if (!state_.sellerId && !state_.defaultSellerId) {
      state_.loading = false
      notification.error({
        message: 'Terjadi kesalahan',
        description: 'Seller data tidak ditemukan',
      })
      return
    }
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    getPaymentHistory({
      business_id: businessId,
      id: route.app._route.params.id,
      seller_id:  state_.sellerId || state_.defaultSellerId,
      params: {
        limit: size.value,
        page: page || page.value,
        paid_at_sort: route.app._route.query.paid_at_sort,
        search_number: route.app._route.query.search_number || undefined,
      },
    })
    .then(({ data: { data: response } }) => {
      state_.dataPayment = response.contents
      state_.pagination.total_items = response.total_elements
    })
    .catch(err => {
      state_.dataPayment = []
      console.error(err)
    })
    .finally(() => state_.loading = false)
  }

  const exportDataTableCurrentPage = async () => {
    state_.loading = true
    if (!state_.sellerId && !state_.defaultSellerId) {
      state_.loading = false
      return notification.error({
        message: 'Gagal Export Data',
        description: 'Seller data tidak ditemukan',
      })
    }
    const businessId = route.app._route.query.business_id || (await getBusinessId())
    getPaymentHistory({
      business_id: businessId,
      id: route.app._route.params.id,
      seller_id:  state_.sellerId || state_.defaultSellerId,
      params: {
        limit: state_.pagination.total_items,
        page: 1,
        paid_at_sort: route.app._route.query.paid_at_sort,
        search_number: route.app._route.query.search_number || undefined,
      },
    })
    .then(({ data: { data: response } }) => {
      const filteredDataByDate = response.contents
      const flattenData = []
      filteredDataByDate.forEach((obj) => {
        if (obj.orders?.length > 0) {
          obj.orders.forEach((order) => {
            flattenData.push({
              payment_number: obj.payment_number,
              payment_id: obj.payment_id,
                payment_amount: obj.payment_amount,
                payment_paid_amount: obj.payment_paid_amount,
                created_at: moment(obj.paid_at).format('DD MMM YYYY, HH:mm'),
                payment_type: obj.payment_type,
                account_number: obj.account_number ?? '-',
                payment_name: obj.payment_name,
                order_number: order.order_number,
                order_id: order.order_id,
                order_date: moment(order.created_at).format('DD MMM YYYY, HH:mm'),
              })
            })
          } else {
            flattenData.push({
              payment_number: obj.payment_number,
            })
          }
        })
        const rowData = [
          "No. Pembayaran",
          "ID Pembayaran",
          "Total Bayar",
          "Total yang Sudah Dibayar",
          "Tanggal Bayar",
          "Metode Pembayaran",
          "Nomor Akun",
          "Nama Pembayaran",
          "No. Order",
          "ID Order",
          "Tanggal Order",
        ]
        flattenData.sort((a, b) => {
          return moment(b.paid_at) - moment(a.paid_at);
        })
        const worksheet = XLSX.utils.json_to_sheet(flattenData);
        const workbook = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
        const findSellerName = state_.distributorSellerList.find(f => f.value === state_.sellerId || state_.defaultSellerId)?.label
        XLSX.writeFile(workbook,`Payment History - ${findSellerName || 'Seller'}.xlsx`)
        state_.loading = false
    })
    .catch(err => {
      state_.exportDataPayment = []
      console.error(err)
    })
    .finally(() => state_.loading = false)
  }

  const getInvoiceNumber = async (param, index) => {
    state_.loading = true

    await getInvoiceDetail({
      business_id: state_.businessId,
      id: param?.orders[0].order_id,
    })
    .then(async ({ data: { data: response } }) => {
      let total_cad_amount = 0
      if (response.payment?.term === 'CAD' &&  param.is_paid_first === false) {
        const { data: { data: listTagihan } } = await getListTagihan({
          business_id: state_.businessId,
          payment_id: param.payment_id,
          channel_id: route.app._route.params.id,
          params: {
            page: 1,
            size: 10,
          },
        })
        if (listTagihan[0]?.orders) {
          total_cad_amount = listTagihan[0].orders.reduce(function (acc, obj) { return acc + obj.order_outstanding_amount }, 0)
        }
      } else {
        total_cad_amount = undefined
      }
      state_.initialData = response.invoice_number
      const getPaymentName = paymentName.find((obj) => obj.value === response?.payment?.term)
      const x = {
        ...state_.dataPayment[index],
        invoice_number: response.invoice_number,
        payment: response.payment,
        payment_name_tag: getPaymentName.label,
        total_cad_amount,
      }
      state_.dataPayment.splice(index, 1, x)
      state_.loading = false
    })
    .catch(err => {
      console.error(err)
      notification.error({
        message: 'Gagal',
        description: err?.response?.data?.message,
      })
      state_.loading = false
    })
  }

  const fetchListTagihan = async (param, page) => {
    await getListTagihan({
      business_id: state_.businessId,
      payment_id: param.payment_id ? param.payment_id : param,
      channel_id: route.app._route.params.id,
      params: {
        page: 1,
        size: 999,
      },
    })
    .then(async ({ data: { data: response } }) => {
      state_.paginationTagihan.total_items = response.total_elements
      const orderId = response[0].orders.map(item => item.order_id).join(',')

      await getInvoiceList({
        business_id: state_.businessId,
        params: { order_ids: orderId },
      })
      .then(({ data: res }) => {
        if (res.data) {
          state_.listTagihan = res.data
            .map((val, index) => {
              const { invoice_number, order_id } = val
              const listTemp = response[0].orders.find(f => f.order_id === order_id)
              if (listTemp) {
                return {
                  ...listTemp,
                  invoice_number,
                  calc_outstanding_amount: listTemp?.order_outstanding_amount - listTemp?.payment_amount,
                }
              }
              return null
            })
            .filter(Boolean)
        }
      })
      .catch(err => {
        console.error(err)
      })
    })
    .catch(err => {
      console.error(err)
      state_.listTagihan = []
    })
  }

  const getWarehouse = async () => {
    if (store.state.user.restriction_base.toLowerCase() == 'business') {
      await getWarehouseList({ business_id: route.app._route.query.business_id })
      .then(({ data }) => state_.warehouseData = data.data)
      .catch(err => console.error(err))
    }
  }

  const handleWarehouse = e => {
    state_.selectedWarehouse = e
  }

  const changePage = (page, pageSize) => {
    route.push({
      query: {
        ...route.app._route.query,
        page: page,
        limit: pageSize,
        search: undefined,
        sort: undefined,
      },
    })
    getListHistoryPayment({
      page,
    })
  }
  const onSearchPaymentNumber = debounce(e => {
    route.push({
      query: {
        ...route.app._route.query,
        search_number: e.target.value,
        page: 1,
      },
    }).catch(() => {})
    getListHistoryPayment({
      page: 1,
    })
  }, 500)
  const onSelectSeller = (value) => {
    state_.sellerId = value
    getListHistoryPayment({
      page: 1,
    })
  }

  const changePageTagihan = value => {
    state_.pages = value
    fetchListTagihan(route.app._route.query.seller_id, value)
  }
  const filterOption = (input, option) => {
    return (
      option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    )
  }

  watch(
    () => route.app._route.query.warehouse_id,
    (value) => {
      if (value) {
        fetchSellerByWhsId(value)
      } else {
        if (state_.distributorSellerList.length > 0) return
        fetchDistributorSeller()
      }
    },
    { immediate: true, deep: true },
  )

  watch(
    () => state_.defaultSellerId,
    (value) => {
      if (value) {
        getListHistoryPayment({
          page: 1,
        })
      }
    },
  )

  return {
    ...toRefs(state_),
    changePage,
    page,
    size,
    handleCreatePayment,
    handleOrderPaid,
    handleWarehouse,
    getListHistoryPayment,
    getInvoiceNumber,
    fetchListTagihan,
    changePageTagihan,
    isRoleSuperAdmin,
    filterOption,
    onSelectSeller,
    exportDataTableCurrentPage,
    searchPaymentNumber,
    onSearchPaymentNumber,
  }
}
